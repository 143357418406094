<template>
  <div class="main">
    <div class="base-info">
<!--      <img class="bg" :src="info.home_banner" />-->
      <img class="cover" v-image="info.logo_img" />
      <div class="info">
        <div class="name">{{info.chinese_name}}</div>
        <div class="name-en">{{info.foreign_name}}</div>
        <div class="area">
          <span>{{info.area}}</span> | <span>{{info.company}}</span>
        </div>
        <div class="level" v-if="info.level_text!==''">
          <van-tag type="warning" size="medium">{{info.level_text}}</van-tag>
        </div>
      </div>

      <div class="intro"> {{info.introduce}}</div>
    </div>

    <div>
      <van-sticky>
        <van-tabs v-model:active="activeCategory" @click-tab="onClickTab" >
          <van-tab title="全部" name="0"></van-tab>
          <van-tab v-for="item in categoryList" :key="item" :title="item.name" :name="item.id"></van-tab>
        </van-tabs>
      </van-sticky>
      <ProductList v-if="!isEmpty" :list="list" :loading="loading" :finished="finished" @onLoad="onLoad" />
      <van-empty v-if="isEmpty" description="该品类下没有商品，换个品类看看吧~" />
    </div>
  </div>
</template>

<script>

import {
  ref,
  onMounted
} from 'vue';
import { useRoute } from 'vue-router';
import {Icon, Tag, Popup, Col, Row, Tabs, Tab, Empty, Sticky} from 'vant';
import Product from "../../models/Product";
import ProductList from "./components/ProductList";

export default {
  setup() {
    const route = useRoute();
    let id = route.query.id;
    let info = ref({});
    const finished = ref(false);
    const loading = ref(false);
    const list = ref([]);
    const page = ref(1);
    const size = 20;
    const isEmpty = ref(false);
    const categoryList = ref([]);
    const activeCategory = ref(0);
    const cid1 = ref(0);

    onMounted(() => {
      let param = {
        'id': id
      }
      Product.brandDetail(param).then(res => {
        info.value = res.data;
      });
      Product.category().then(res => {
        categoryList.value = res.data.list;
      })
      onLoad();
    });

    const onLoad = () => {
      if (loading.value) {
        return ;
      }

      loading.value = true;
      let param = {
        'brand_id': id,
        'cid1': cid1.value,
        'page': page.value,
        'size': size
      }
      Product.brandProduct(param).then(res => {
        let dataList = res.data.list ? res.data.list : [];
        if (page.value === 1) {
          list.value = dataList;
          if (!dataList.length) {
            isEmpty.value = true;
          } else {
            isEmpty.value = false;
          }
          console.log(isEmpty.value)
        } else {
          dataList.forEach(value => {
            list.value.push(value)
          })
        }
        if (dataList.length < size) {
          finished.value = true;
        }
        page.value += 1;
        loading.value = false
      })
    }

    const onClickTab = (e) => {
      cid1.value = e.name;
      page.value = 1;
      onLoad();
    }
    return {
      info,
      finished,
      loading,
      list,
      isEmpty,
      categoryList,
      activeCategory,
      onLoad,
      onClickTab
    };
  },

  components: {
    [Icon.name]: Icon,
    [Tag.name]:Tag,
    [Popup.name]:Popup,
    [Col.name]:Col,
    [Row.name]:Row,
    [Tabs.name]:Tabs,
    [Tab.name]:Tab,
    [Empty.name]: Empty,
    [Sticky.name]: Sticky,
    ProductList
  }
};
</script>
<style scoped>

.base-info {
  background-color: #fff;
  position: relative;
  padding: 50px 15px 10px;
}
.base-info .cover {
  width: 100px;
  height: 100px;
  border: solid 1px #f8f8f8;
  box-shadow: 2px 2px 10px 2px #f0f0f0;
  float: left;
  border-radius: 10px;
}
.base-info .info {
  margin-left: 120px;
}
.base-info .name {
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
}
.name-en, .area, .intro {
  font-size: 14px;
  line-height: 22px;
}
.base-info .level {
  position: absolute;
  right: 20px;
  top: 60px;
}
.base-info .intro {
  clear: both;
  padding: 15px 0;
}


</style>
